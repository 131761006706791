export const AGENCY_ROUTE_NAMES = Object.freeze({
  AGENCY_EDIT: "agencies-edit",
  AGENCY_PROFILE: "agency-profile",
});

export default [
  {
    path: "/agencies/:agency_id",
    name: AGENCY_ROUTE_NAMES.AGENCY_EDIT,
    component: () => import("@/modules/agency/views/AgencyEdit.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Agences",
          active: false,
          to: "/agencies",
        },
        {
          text: "Profil",
          active: true,
        },
      ],
    },
  },
  {
    path: "/agencies/:agency_id",
    name: AGENCY_ROUTE_NAMES.AGENCY_PROFILE,
    component: () => import("@/modules/agency/views/AgencyEdit.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Votre agence",
          active: true,
        },
      ],
    },
  },
  {
    path: "/groups",
    name: "agency-groups",
    component: () => import("@/views/groups/Groups.vue"),
    meta: {
      pageTitle: "Groupes",
      breadcrumb: [
        {
          text: "Groupes",
          active: true,
        },
      ],
    },
  },
  {
    path: "/agency/reservations",
    name: "agency-reservations",
    component: () => import("@/modules/agency/views/AgencyReservations.vue"),
    meta: {
      pageTitle: "Réservations",
      breadcrumb: [
        {
          text: "Mes réservations",
          active: true,
        },
      ],
    },
  },
  {
    path: "/agency/reportings",
    name: "agency-reporting",
    component: () => import("@/views/organisation/reporting/Reporting.vue"),
    meta: {
      pageTitle: "Rapports",
      breadcrumb: [
        {
          text: "Rapports",
          active: true,
        },
      ],
    },
  },
];
