import Vue from 'vue';

// axios
import axios from 'axios';
import store from '@/store';
import {ACCESS_TOKEN_GETTER, AUTH_STORE_NAMESPACE} from "@/modules/authnz/models/store";
import authenticationConfig from "@/configuration/authentication-config";
import Tenant from "@/plugins/tenant";

let apiUrl = process.env.VUE_APP_API_URL;

const tenantId = Tenant.resolveTenantId();

const axiosIns = axios.create({
  baseURL: apiUrl,
  headers: {
    'X-TENANT-ID': tenantId
  }
});

axiosIns.defaults.headers.common['X-TENANT-ID'] = tenantId;

axiosIns.interceptors.request.use(
  config => {
    const accessToken = store.getters[`${AUTH_STORE_NAMESPACE}/${ACCESS_TOKEN_GETTER}`];

    if (accessToken) {
      config.headers.Authorization = `${authenticationConfig.tokenType} ${accessToken}`;
    }
    return config;
  },
  error => Promise.reject(error),
);

export default axiosIns;
