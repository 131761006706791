export const GENERIC_ROUTES_NAMES = Object.freeze({
  HOME: "home",
});

const buildGroupBreadcrumb = (pageTitle) => {
  return [
    {
      text: "Groupes",
      active: false,
      to: "/groups",
    },
    {
      active: true,
      fetch: true,
      resource: "app-groups/getCurrentGroupObject",
    },
    {
      text: pageTitle,
      active: true,
    },
  ];
};

export default [
  {
    path: "/",
    name: GENERIC_ROUTES_NAMES.HOME,
    component: () => import("@/views/organisation/overview/Home.vue"),
    meta: {
      pageTitle: "Tableau de bord",
      breadcrumb: [
        {
          text: "Tableau de bord",
          active: true,
        },
      ],
    },
  },
  {
    path: "/groups/creation-wizard",
    name: "group-create",
    component: () =>
      import("@/modules/group/creation-wizard/GroupCreationWizard.vue"),
    meta: {
      pageTitle: "Création de groupe",
      breadcrumb: [
        {
          text: "Groupes",
          active: true,
        },
      ],
    },
  },
  {
    path: "/groups/:id",
    name: "organisation-group",
    meta: {
      pageTitle: "Groupes",
      breadcrumb: [
        {
          text: "Groupes",
          active: true,
        },
      ],
    },
    component: () => import("@/views/groups/group/GroupPage.vue"),
    redirect: "/groups/:id/summary",
    children: [
      {
        path: "/",
        redirect: "summary",
      },
      {
        path: "summary",
        name: "group-summary",
        component: () => import("@/views/groups/group/GroupSummaryTab.vue"),
        meta: {
          pageTitle: "Sommaire",
          breadcrumb: buildGroupBreadcrumb("Sommaire"),
        },
      },
      {
        path: "itineraries",
        name: "group-itineraries",
        component: () => import("@/views/groups/group/GroupItinerariesTab.vue"),
        meta: {
          pageTitle: "Itinéraires",
          breadcrumb: buildGroupBreadcrumb("Itinéraires"),
        },
      },
      {
        path: "reservations",
        name: "group-reservations",
        component: () =>
          import("@/views/groups/group/GroupReservationsTab.vue"),
        meta: {
          pageTitle: "Réservations",
          breadcrumb: buildGroupBreadcrumb("Réservations"),
        },
      },
      {
        path: "inventory",
        name: "group-inventory",
        component: () => import("@/views/groups/group/GroupInventoriesTab.vue"),
        meta: {
          pageTitle: "Inventaires",
          breadcrumb: buildGroupBreadcrumb("Inventaires"),
        },
      },
      {
        path: "passengers",
        name: "group-passengers",
        component: () => import("@/views/groups/group/GroupPassengersTab.vue"),
        meta: {
          pageTitle: "Liste des passagers",
          breadcrumb: buildGroupBreadcrumb("Liste des passagers"),
        },
      },
      {
        path: "details",
        name: "group-details",
        component: () =>
          import("@/views/groups/group/GroupDetailsParentTab.vue"),
        meta: {
          pageTitle: "Détails du groupe",
          breadcrumb: buildGroupBreadcrumb("Détails du groupe"),
        },
        children: [
          {
            path: "",
            component: () => import("@/views/groups/group/GroupDetailsTab.vue"),
            meta: {
              pageTitle: "Détails du groupe",
              breadcrumb: buildGroupBreadcrumb("Détails du groupe"),
            },
          },
          {
            path: "transportation",
            component: () =>
              import("@/views/groups/group/GroupDetailsTransportationTab.vue"),
            meta: {
              pageTitle: "Détails du groupe",
              breadcrumb: buildGroupBreadcrumb("Détails du groupe"),
            },
          },
          {
            path: "accommodation",
            component: () =>
              import("@/views/groups/group/GroupDetailsAccommodationTab.vue"),
            meta: {
              pageTitle: "Détails du groupe",
              breadcrumb: buildGroupBreadcrumb("Détails du groupe"),
            },
          },
        ],
      },
      {
        path: "packages",
        name: "group-packages",
        component: () => import("@/views/groups/group/GroupPackagesTab.vue"),
        meta: {
          pageTitle: "Forfaits",
          breadcrumb: buildGroupBreadcrumb("Forfaits"),
        },
      },
      {
        path: "billing",
        name: "group-billing",
        component: () => import("@/views/groups/group/GroupBillingTab.vue"),
        meta: {
          pageTitle: "Facturation",
          breadcrumb: buildGroupBreadcrumb("Facturation"),
        },
        children: [
          {
            path: "",
            redirect: "sheets",
          },
          {
            path: "products",
            component: () =>
              import("@/views/groups/group/GroupBillingProductsTab.vue"),
            meta: {
              pageTitle: "Facturation",
              breadcrumb: buildGroupBreadcrumb("Facturation"),
            },
          },
          {
            path: "sheets",
            component: () =>
              import("@/views/groups/group/GroupBillingSheetsTab.vue"),
            meta: {
              pageTitle: "Facturation",
              breadcrumb: buildGroupBreadcrumb("Facturation"),
            },
          },
        ],
      },
      {
        path: "documents",
        name: "group-documents",
        component: () => import("@/views/groups/group/GroupDocumentsTab.vue"),
        meta: {
          pageTitle: "Documents",
          breadcrumb: buildGroupBreadcrumb("Documents"),
        },
      },
    ],
  },
  {
    path: "/groups/:id/reservation",
    name: "group-add-reservation",
    component: () =>
      import("@/views/groups/group/GroupReservations/GroupReservations.vue"),
    meta: {
      pageTitle: "Ajouter une réservation",
      breadcrumb: [
        {
          text: "Groupes",
          active: false,
          to: "/groups",
        },
        {
          active: true,
          fetch: true,
          resource: "app-groups/getCurrentGroupObject",
        },
      ],
    },
  },
  {
    path: "/groups/:id/reservation/:reservation_id",
    name: "group-edit-reservation",
    component: () =>
      import(
        "@/views/groups/group/GroupReservations/EditReservation/GroupReservation.vue"
      ),
    meta: {
      pageTitle: "Modifier une réservation",
      breadcrumb: [
        {
          text: "Groupes",
          active: false,
          to: "/groups",
        },
        {
          active: true,
          fetch: true,
          resource: "app-groups/getCurrentGroupObject",
        },
      ],
    },
  },
  {
    path: "/groups/:id/reservation/:reservation_id/confirmation",
    name: "group-confirm-reservation",
    component: () =>
      import(
        "@/views/groups/group/GroupReservations/EditReservation/GroupReservationConfirmation.vue"
      ),
    meta: {
      pageTitle: "Confirmation d'une réservation",
      breadcrumb: [
        {
          text: "Groupes",
          active: false,
          to: "/groups",
        },
        {
          active: true,
          fetch: true,
          resource: "app-groups/getCurrentGroupObject",
        },
      ],
    },
  },
  {
    path: "/groups/:id/package",
    name: "group-add-package",
    component: () =>
      import("@/views/groups/group/GroupPackages/GroupPackage.vue"),
    meta: {
      pageTitle: "Créer un forfait",
      breadcrumb: [
        {
          text: "Groupes",
          active: false,
        },
        {
          active: true,
          fetch: true,
          resource: "app-groups/getCurrentGroupObject",
        },
        {
          text: "Créer un forfait",
          active: true,
        },
      ],
    },
  },
  {
    path: "/groups/:id/package/:package_id",
    name: "group-edit-package",
    component: () =>
      import("@/views/groups/group/GroupPackages/GroupPackage.vue"),
    meta: {
      pageTitle: "Modifier un forfait",
      breadcrumb: [
        {
          text: "Groupes",
          active: false,
        },
        {
          active: true,
          fetch: true,
          resource: "app-groups/getCurrentGroupObject",
        },
        {
          text: "Modifier un forfait",
          active: true,
        },
      ],
    },
  },
  {
    path: "/groups/:group_id/billing/:pack_id/:occup_id/:traveler_id",
    name: "group-edit-billing-sheet",
    component: () =>
      import("@/views/groups/group/GroupBilling/GroupBillingSheet.vue"),
    meta: {
      pageTitle: "Fiches comptables",
      breadcrumb: [
        {
          text: "Groupes",
          active: false,
        },
        {
          active: true,
          fetch: true,
          resource: "app-groups/getCurrentGroupObject",
        },
        {
          text: "Fiches comptables",
          fetch: true,
          resource: "app-groups/getCurrentGroupBillings",
        },
      ],
    },
  },
  {
    path: "/members/:id",
    name: "member-edit",
    component: () => import("@/views/administrators/members/MemberEdit.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Agences",
          active: false,
        },
        {
          text: "Conseillers",
          active: false,
        },
      ],
    },
  },
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/error/Error404.vue"),
    meta: {
      layout: "full",
    },
  },
];
