import axios from '@axios'
import store from '@/store'

export default {
  namespaced: true,
  state: {
    currentUser: {
      fullName: ""
    }
  },
  getters: {
    isOrganisationAdmin: state => {
      return state.currentUser.role == 'organization_admin'
    },
    getCurrentRole: state => {
      return state.currentUser.role
    }
  },
  mutations: {
    setUser(state, user) {
      state.currentUser = user
    }
  },
  actions: {
    fetchCurrentLoggedUser(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get(`users/me`)
          .then(response => {
            let data = {
              general: {
                avatar: require('@/assets/images/portrait/small/avatar-s-11.jpg'),
                username: response.data.email,
                fullName: `${response.data.first_name.trim()} ${response.data.last_name.trim()}`,
                email: response.data.email,
                role: response.data.role,
              }
            }
            store.commit('app-users/setUser', data.general)
            resolve(data)
          })
          .catch(error => reject(error))
      })
    },

  },
}
