export default [
  {
    id: 1,
    avatar: require('@/assets/images/avatars/3-small.png'),
    fullName: "Veronica Peric",
    email: 'vero@clubvoyage.com',
    startDate: '09/23/2016',
    role: 'agency_admin',
  },
  {
    id: 2,
    avatar: require('@/assets/images/avatars/5-small.png'),
    fullName: 'Bailie Coulman',
    email: 'bcoulman1@yolasite.com',
    startDate: '05/20/2018',
    role: 'agency_admin',
  },{
    id: 3,
    avatar: require('@/assets/images/avatars/4-small.png'),
    fullName: "Cristiano Ronaldo",
    email: 'vero@clubvoyage.com',
    startDate: '09/23/2016',
    role: 'agent', 
  },
  {
    id: 4,
    avatar: require('@/assets/images/avatars/2-small.png'),
    fullName: 'Lionel Messi',
    email: 'bcoulman1@yolasite.com',
    startDate: '05/20/2018',
    role: 'agent',
  }];
