export default [
  {
    id: 1,
    avatar: require('@/assets/images/avatars/6-small.png'),
    fullName: "Veronica Peric",
    firstName: "Veronica",
    lastName: "Peric",
    email: 'vero@clubvoyage.com',
    startDate: '09/23/2016',
    phone: '4186547894',
    role: 'organisation_admin',
  },
  {
    id: 2,
    avatar: require('@/assets/images/avatars/7-small.png'),
    fullName: 'Bailie Coulman',
    firstName: "Bailie",
    lastName: "Coulman",
    email: 'bcoulman1@yolasite.com',
    startDate: '05/20/2018',
    phone: '4187896541',
    role: 'organisation_admin',
  },{
    id: 3,
    avatar: require('@/assets/images/avatars/8-small.png'),
    fullName: "Mike Roger",
    firstName: "Mike",
    lastName: "Roger",
    email: 'vero@clubvoyage.com',
    startDate: '09/23/2016',
    phone: '5814875486',
    role: 'organisation_admin', 
  },
  {
    id: 4,
    avatar: require('@/assets/images/avatars/9-small.png'),
    fullName: 'Bailie Coulman',
    firstName: "Bailie",
    lastName: "Coulman",
    email: 'bcoulman1@yolasite.com',
    startDate: '05/20/2018',
    phone: '4559876541',
    role: 'organisation_coordinator',
  }];
