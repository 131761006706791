// constsPlugin.js
import {AGENCY_ROUTE_NAMES} from "@/router/agency";
import {GENERIC_ROUTES_NAMES} from "@/router/app";

const RouteNames = {
  ...GENERIC_ROUTES_NAMES,
  ...AGENCY_ROUTE_NAMES,
};

RouteNames.install = function (Vue) {
  Vue.prototype.$routeNameOf = (key) => {
    return RouteNames[key];
  };
};

export default RouteNames;
