export default [
  {
    id: 1,
    name: 'Famille Béland',
    type: 'cruise',
    primary_contact: 'Dominique Langlois',
    package_name: 'Départ Québec- Catégorie 4*',
    date: '2021-10-23',
    agent: 'Véronic Péric',
    status: 'completed'
  },{
    id: 2,
    name: 'Claude Denis',
    type: 'cruise',
    primary_contact: 'Claude Denis',
    package_name: 'Départ Québec- Catégorie 3*',
    date: '2021-11-11',
    agent: 'Véronic Péric',
    status: 'in_progress'
  },{
    id: 3,
    name: 'Nadia Drouin',
    type: 'cruise',
    primary_contact: 'Nadia Drouin',
    package_name: 'Départ Québec- Montreal 4*',
    date: '2021-11-21',
    agent: 'Gabriel Lafleur',
    status: 'in_progress'
  }]