export default {
  // TODO this should be configurable via ENV variable or build config file
  // Endpoints
  authEndpoint: 'authnz/sign-in',
  authCallback: 'authnz/oauth2/callback',
  refreshEndpoint: 'authnz/renewal',
  logoutEndpoint: 'authnz/sign-out',

  // This will be prefixed in authorization header with the access token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of those properties will be used as a keys to store the targeted tokens
  accessTokenStoreKey: 'accessToken',
  refreshTokenStoreKey: 'refreshToken',
};
