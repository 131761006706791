import Vue from "vue";
import mixpanel from 'mixpanel-browser';

export default function setup() {
  const isProduction = process.env.NODE_ENV === 'production';

  if (isProduction) {
    mixpanel.init('5ae2f3c5946e346e89ed4db10b4d98fb', {debug: false}); 
  } else {
    console.debug('Setting Up Mixpanel for Local')

    mixpanel.init('58b9a593647ca370bef3b6102e2de88e', {debug: true}); 
  }

  Vue.use(mixpanel);
}
