var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100",class:[_vm.skinClasses],attrs:{"id":"app"}},[(
      _vm.isGroupX &&
      _vm.showBanner &&
      _vm.urlRedirectionWarningPeriodHasBegun &&
      !_vm.urlRedirectionPeriodHasEnded
    )?_c('div',{staticClass:"bg-success text-white text-center py-auto",staticStyle:{"z-index":"999","position":"sticky","top":"0","width":"100%","height":"50px","padding-top":"12.5px"},attrs:{"id":"banner"}},[(
        _vm.urlRedirectionWarningPeriodHasBegun && !_vm.urlRedirectionPeriodHasBegun
      )?_c('span',[_c('strong',[_vm._v("Message important")]),_vm._v(": Veuillez prendre note qu'à compter du 11 octobre, une nouvelle adresse vous sera communiquée pour accéder à GroupX. ")]):_vm._e(),(_vm.urlRedirectionPeriodHasBegun && !_vm.urlRedirectionPeriodHasEnded)?_c('span',[_c('strong',[_vm._v("Message important")]),_vm._v(": Veuillez prendre note que l'adresse pour accéder à GroupX a changé pour "),_c('a',{attrs:{"href":"https://groupx.monicagroups.com"}},[_vm._v("groupx.monicagroups.com")]),_vm._v(". ")]):_vm._e(),_c('b-button',{staticClass:"ml-2",attrs:{"variant":"outline-light","size":"sm"},on:{"click":function($event){_vm.showBanner = false}}},[_vm._v("OK")])],1):_vm._e(),_c(_vm.layout,{tag:"component"},[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }