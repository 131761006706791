import jwt_decode from "jwt-decode";
import {TokenValidationError} from "@/modules/authnz/models/errors";
import authenticationConfig from "@/configuration/authentication-config";

export function decodeAndValidate(rawAccessToken) {
  let accessToken = jwt_decode(rawAccessToken);

  if (accessToken.typ !== authenticationConfig.tokenType) {
    throw new TokenValidationError(`Invalid token type ${accessToken.typ}`);
  }

  if (!accessToken.sub || !accessToken.groups) {
    throw new TokenValidationError('Missing or invalid data in the access token');
  }

  return accessToken;
}
