import {AuthorizationError} from "@/modules/authnz/models/errors";

const RolesRawRepresentation = Object.freeze({
  ORGANIZATION_ADMIN: 'organization_admin',
  ORGANIZATION_COORDINATOR: 'organization_coordinator',
  AGENCY_ADMIN: 'agency_admin',
  AGENCY_AGENT: 'agency_agent',
  GUEST: 'guest',
});

const RolesDisplayableName = {
  'organization_admin': 'Administrateur d\'organisation',
  'organization_coordinator': 'Coordonnateur d\'organization',
  'agency_admin': 'Administrateur d\'agence',
  'agency_agent': 'Agent(e)',
  'guest': 'Invité'
};

export default class Roles {
  role = null;
  displayableRole = null;

  static ORGANIZATION_ADMIN = new Roles(RolesRawRepresentation.ORGANIZATION_ADMIN);
  static ORGANIZATION_COORDINATOR = new Roles(RolesRawRepresentation.ORGANIZATION_COORDINATOR);
  static AGENCY_ADMIN = new Roles(RolesRawRepresentation.AGENCY_ADMIN);
  static AGENCY_AGENT = new Roles(RolesRawRepresentation.AGENCY_AGENT);
  static GUEST = new Roles(RolesRawRepresentation.GUEST);

  constructor(role) {
    this.role = role;
    this.displayableRole = RolesDisplayableName[role];
  }

  static mapRole(roles) {
    if (!Array.isArray(roles) || roles.length !== 1) {
      throw new AuthorizationError(`Invalid provided roles ${roles}, it must be an array of one role`);
    }

    switch (roles[0].toLowerCase()) {
      case RolesRawRepresentation.ORGANIZATION_ADMIN:
        return Roles.ORGANIZATION_ADMIN;
      case RolesRawRepresentation.ORGANIZATION_COORDINATOR:
        return Roles.ORGANIZATION_COORDINATOR;
      case RolesRawRepresentation.AGENCY_ADMIN:
        return Roles.AGENCY_ADMIN;
      case RolesRawRepresentation.AGENCY_AGENT:
        return Roles.AGENCY_AGENT;
      default:
        throw new AuthorizationError(`Provided role ${roles} is invalid or not supported`);
    }
  }

  associatedResourceClaim() {
    if (this._isOrganizationRole()) {
      return 'organization_id';
    }

    if (this._isAgencyRole()) {
      return 'agency_id';
    }

    return undefined;
  }

  _isOrganizationRole() {
    return this.role === Roles.ORGANIZATION_ADMIN.role || this.role === Roles.ORGANIZATION_COORDINATOR.role;
  }

  _isAgencyRole() {
    return this.role === Roles.AGENCY_ADMIN.role || this.role === Roles.AGENCY_AGENT.role;
  }

  static isSame(currentRole, expectedRole) {
    return currentRole.role === expectedRole.role;
  }

  static roleIsAllowed(currentRole, allowedRoles) {
    if (Array.isArray(allowedRoles)) {
      return allowedRoles.includes(currentRole)
    } else {
      return false;
    }
  }

  static isAgencyRole(role) {
    console.log('current role', role);
    return role === Roles.AGENCY_ADMIN.role || role === Roles.AGENCY_AGENT.role;
  }
}
