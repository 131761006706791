import Vue from 'vue';
import VueRouter from 'vue-router';
import authnz from "@/router/authnz";
import app from "@/router/app";
import admins from "@/router/admins";
import organisation from "@/router/organisation";
import agency from './agency.js'
import {AUTH_STORE_NAMESPACE, IS_AUTHENTICATED_GETTER} from "@/modules/authnz/models/store";
import store from '@/store';
import users from "@/router/users";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {x: 0, y: 0};
  },
  routes: [
    ...app,
    ...authnz,
    ...users,
    ...admins,
    ...organisation,
    ...agency,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
});


router.beforeEach(async (to, _, next) => {
  const isAuthenticated = await store.getters[`${AUTH_STORE_NAMESPACE}/${IS_AUTHENTICATED_GETTER}`];

  router.app.$log.debug(`User navigating from ${_.path} to ${to.path} is ${isAuthenticated ? '' : 'not '}authenticated`);
  
  if (to.name === 'login' || to.path.startsWith('/auth') || to.meta.skipAuth) {
    return next();
  } else {
    if (!isAuthenticated) return next({name: 'login'});

    // if (!canNavigate(to)) {
    //   // Redirect to login if not logged in
    //   if (!isAuthenticated) return next({name: 'login'});
    //
    //   // If logged in => not authorized
    //   return next({name: 'misc-not-authorized'});
    // }
    //
    // // Redirect if logged in
    // if (to.meta.redirectIfLoggedIn && isAuthenticated) {
    //   const userData = getUserData();
    //   next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
    // }
    return next();
  }

  // if (!canNavigate(to)) {
  //   // Redirect to login if not logged in
  //   if (!isAuthenticated) return next({name: 'login'});
  //
  //   // If logged in => not authorized
  //   return next({name: 'misc-not-authorized'});
  // }
  //
  // // Redirect if logged in
  // if (to.meta.redirectIfLoggedIn && isAuthenticated) {
  //   const userData = getUserData();
  //   next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
  // }
});

export default router;
