export class AuthenticationError extends Error {
  constructor(message) {
    super(message);
  }
}

export class TokenValidationError extends Error {
  constructor(message) {
    super(message);
  }
}

export class AuthorizationError extends Error {
  constructor(message) {
    super(message);
  }
}
