export const AUTHNZ_ROUTES_NAMES = Object.freeze({
  LOGIN: 'login',
  LOGIN_CALLBACK: 'authentication-callback',
  AUTHN_ERRORS: 'authentication-errors',
});

export default [
  {
    path: '/login',
    name: AUTHNZ_ROUTES_NAMES.LOGIN,
    component: () => import('@/modules/authnz/views/Login.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/auth/callback',
    name: AUTHNZ_ROUTES_NAMES.LOGIN_CALLBACK,
    component: () => import('@/modules/authnz/views/Callback.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/auth/error',
    name: AUTHNZ_ROUTES_NAMES.AUTHN_ERRORS,
    component: () => import('@/modules/authnz/views/Error.vue'),
    meta: {
      layout: 'full',
    },
  },
];
