export default [
  {
    path: '/agencies',
    name: 'agencies',
    component: () => import('@/modules/organization/views/AgenciesList.vue'),
    meta: {
      pageTitle: 'Agences',
      breadcrumb: [
        {
          text: 'Agences',
          active: true,
        },
      ],
    },
  },
  {
    path: '/groups',
    name: 'organisation-groups',
    component: () => import('@/views/groups/Groups.vue'),
    meta: {
      pageTitle: 'Groupes',
      breadcrumb: [],
    },
  },
  {
    path: '/public/groups/:id',
    name: 'organisation-group-public',
    component: () => import('@/views/public/GroupPublic.vue'),
    meta: {
      layout: 'public',
      pageTitle: 'Groupe',
      skipAuth: true
      
    },
  },
  {
    path: '/public/groups/:id/reservations/:reservation_id',
    name: 'group-reservation-public',
    component: () => import('@/views/public/GroupReservationPublic.vue'),
    meta: {
      layout: 'public',
      pageTitle: 'Groupe',
      skipAuth: true
      
    },
  },
  {
    path: '/public/groups/:id/reservation/:reservation_id/confirmation',
    name: 'group-confirm-reservation-public',
    component: () => import('@/views/groups/group/GroupReservations/EditReservation/GroupReservationConfirmation.vue'),
    meta: {
      layout: 'public',
      pageTitle: 'Groupe',
      skipAuth: true
      
    },
  },
  {
    path: '/reservations',
    name: 'organisation-reservations',
    component: () => import('@/views/organisation/reservations/Reservations.vue'),
    meta: {
      pageTitle: 'Réservations',
      breadcrumb: [
        {
          text: 'Mes réservations',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organisation/overview',
    name: 'organisation-overview',
    component: () => import('@/views/organisation/overview/Overview.vue'),
    meta: {
      pageTitle: 'Aperçu',
      breadcrumb: [
        {
          text: 'Aperçu',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organisation/reportings',
    name: 'organisation-reporting',
    component: () => import('@/views/organisation/reporting/Reporting.vue'),
    meta: {
      pageTitle: 'Rapports',
      breadcrumb: [
        {
          text: 'Rapports',
          active: true,
        },
      ],
    },
  }
]
