import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';
import adminStoreModule from '@/views/administrators/admins/adminStoreModule';
import agencyStoreModule from '@/views/organisation/agencies/agencyStoreModule';
import groupStoreModule from '@/views/groups/groupStoreModule';
import userStoreModule from '@/modules/users/userStoreModule';
import authStoreModule, {AUTH_STORE_NAMESPACE} from '@/modules/authnz/models/store.js';
import createMultiTabState from 'vuex-multi-tab-state';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-admins': adminStoreModule,
    'app-agencies': agencyStoreModule,
    'app-groups': groupStoreModule,
    'app-users': userStoreModule,
    [AUTH_STORE_NAMESPACE]: authStoreModule,
  },
  strict: process.env.DEV,
  plugins: [
    createMultiTabState(),
  ],
});
