export default [
  {
    path: '/users/:id/profile',
    name: 'user-profile',
    component: () => import('@/modules/users/views/UserProfile.vue'),
    meta: {
      pageTitle: 'Profil',
      breadcrumb: [
        {
          text: 'Profil',
          active: true,
        },
      ],
    },
  },
]
