import Tenant from "@/plugins/tenant";

/* eslint-disable no-undef */
// Theme Colors
// Initially this will be blank. Later on when app is initialized we will assign bootstrap colors to this from CSS variables.
export const $themeColors = {};

// App Breakpoints
// Initially this will be blank. Later on when app is initialized we will assign bootstrap breakpoints to this object from CSS variables.
export const $themeBreakpoints = {};

const tenantId = Tenant.resolveTenantId();

let appLogo = require('@/assets/images/logo/GroupX_Logo_Clr.png');
if (tenantId === "transat" || tenantId === "groupx") {
  const currentURL = new URL(window.location.href);
  if (currentURL.pathname.startsWith("/public/")) {
    appLogo = require("@/assets/images/logo/groupetco.jpg");
  }
}
try {
  appLogo = require("@/assets/images/logo/" + tenantId + ".jpg")
} catch(e) {
  console.debug('unable to load logo', e)
}

// APP CONFIG
export const $themeConfig = {
  app: {
    appName: 'Monica', // Will update name in navigation menu (Branding)
    // eslint-disable-next-line global-require
    appLogoImage: appLogo,
  },
  layout: {
    isRTL: false,
    skin: 'light', // light, dark, bordered, semi-dark
    routerTransition: 'zoom-fade', // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      hidden: false,
      isCollapsed: false,
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: '', // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static', // static, sticky, hidden
    },
    customizer: true,
    enableScrollToTop: true,
  },
};
