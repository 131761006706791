import Vue from "vue";
import {ModalPlugin, ToastPlugin} from "bootstrap-vue";
import VueAxios from "vue-axios";
import VueLuxon from "vue-luxon";
import CompositionAPI, {provide} from "@vue/composition-api";

import httpClient from "@/shared/libs/http-client";

import setupLogger from "@/plugins/vue-logger";
import RouteNames from "@/plugins/route-names";
import setupMixpanel from "@/plugins/mixpanel";
import Tenant from "@/plugins/tenant";

export default function setupAllPlugins() {
  setupLogger();
  setupMixpanel();
  
  // BSV Plugin Registration
  Vue.use(ToastPlugin);
  Vue.use(ModalPlugin);
  Vue.use(VueLuxon);
  Vue.use(Tenant);


  // HTTP client backed by axios
  Vue.use(VueAxios, httpClient);

  Vue.use(RouteNames);

  // Composition API
  Vue.use(CompositionAPI);
}
