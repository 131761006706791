import {reactive, toRefs} from "@vue/composition-api";
import {getCurrentInstance} from "@/shared/composables/vue-component-instance";

export const useApplicationContext = () => {
  const instance = getCurrentInstance();
  const currentRoute = reactive({route: instance.proxy.$route});

  const refOf = (wantedRef) => instance.proxy.$refs[wantedRef];

  return {
    httpClient: instance.proxy.$http,
    logger: instance.proxy.$log,
    store: instance.proxy.$store,
    router: instance.proxy.$router,
    ...toRefs(currentRoute),

    refOf,
    forceUpdate: () => instance.proxy.$forceUpdate(),
  };
};
