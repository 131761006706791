import {getCurrentInstance as getVm} from "@vue/composition-api";

export function getCurrentInstance() {
  const instance = getVm();

  if (!instance) {
    throw new ReferenceError(
      "[monica] Could not retrieve current vue instance, not found!!" +
      "should only be called from component setup() only!!"
    );
  }

  return instance;
}
