import {useApplicationContext} from "@/shared/composables/use-application-context";

export const useOrganizationApi = () => {
  const {logger, httpClient} = useApplicationContext();


  const getOrganizationSettings = async (publicAccessToken) => {
    let url = `/organization/settings`;
    if (publicAccessToken) {
      url += `?token=${publicAccessToken}`;
    }

    try {
      logger.debug(`Get Org Settings `);
      const response = await httpClient.get(url);
      return response.data;
    } catch (error) {
      logger.error('Failed to get org settings', error);
      throw  error;
    }
  };


  return {
    getOrganizationSettings
  };
};
