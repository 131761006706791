const resolveTenantId = () => {
  const host = window.location.host;
  const parts = host.split('.');
  
  let tenantId = "transat";
  if (parts.length >= 3 && parts[0] !== 'staging') {
    tenantId = parts[0];
  }

  if (tenantId.toLocaleLowerCase() === "groupx") {
    tenantId = "transat"
  }

  return tenantId;
};

const install = function (Vue) {
  Vue.prototype.$tenant = resolveTenantId;
}

export default {
  resolveTenantId,
  install
}
