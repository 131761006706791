export default [
  {
    id: 1,
    avatar: require('@/assets/images/logo/premont.jpg'),
    agencyName: "Club Voyage Élysée",
    email: "info@elysee.com",
    phone: "4186598956",
    branchNumber: '232',
    status: 'active'
  },
  {
    id: 2,
    avatar: require('@/assets/images/logo/alpha.jpg'),
    agencyName: "Club Voyage Fascination",
    email: "support@fascination.com",
    phone: "8194589865",
    branchNumber: '554',
    status: 'pending'
  }];
