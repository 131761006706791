export default [
 {
    path: '/administrators',
    name: 'administrators',
    component: () => import('@/views/administrators/admins/Admins.vue'),
    meta: {
      pageTitle: 'Administrateurs',
      breadcrumb: [
        {
          text: 'Administrateurs',
          active: true,
        },
      ],
    },
  },{
    path: '/administrators/:id',
    name: 'administrators-edit',
    component: () => import('@/views/administrators/admins/AdminEdit/AdminEdit.vue'),
    meta: {
      pageTitle: 'Administrateurs',
      breadcrumb: [
        {
          text: 'Administrateurs',
          active: false,
          to: '/administrators'
        },{
          text: 'Profil',
          active: true,
        },
      ],
    },
  }
]