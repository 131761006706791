export default [
  {
    traveler_id: 1021,
    room: 'Chambre 1',
    occupation: 'double',
    primary_contact: 'Dominique Langlois',
    package_name: 'Départ Québec- Catégorie 4*',
    dob: '1990-10-23',
    agent: 'Véronic Péric',
    sexe: 'F',
  },{
    traveler_id: 1021,
    room: 'Chambre 1',
    occupation: 'double',
    primary_contact: 'Guillaume Verret',
    package_name: 'Départ Québec- Catégorie 4*',
    dob: '1989-11-13',
    agent: 'Véronic Péric',
    sexe: 'M',
  },{
    traveler_id: 1022,
    room: 'Chambre 2',
    occupation: 'single',
    primary_contact: 'Claude Denis',
    package_name: 'Départ Québec- Catégorie 3*',
    dob: '1986-05-17',
    agent: 'Véronic Péric',
    sexe: 'F',
  },{
    traveler_id: 1023,
    room: 'Chambre 3',
    occupation: 'double',
    primary_contact: 'Nadia Drouin',
    package_name: 'Départ Québec- Montreal 4*',
    dob: '1962-08-04',
    agent: 'Gabriel Lafleur',
    sexe: 'F',
  },{
    traveler_id: 1023,
    room: 'Chambre 3',
    occupation: 'double',
    primary_contact: 'Michel Drouin',
    package_name: 'Départ Québec- Montreal 4*',
    dob: '1964-06-21',
    agent: 'Gabriel Lafleur',
    sexe: 'M',
  }]