import agencies from '@mocks/mockAgencies'
import members from '@mocks/mockAgencyMembers'
import axios from '@axios'



export default {
  namespaced: true,
  state: {
    agency_id: null
  },
  getters: {},
  mutations: {
    setCurrentAgencyId(state, id) {
      state.agency_id = id;
    }
  },
  actions: {

    fetchAgencies(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/agencies`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAgency(ctx, queryParams) {
      let agency_id = queryParams.id;

      return new Promise((resolve, reject) => {
        axios
          .get(`/agencies/${agency_id}`)
          .then(
            response => resolve(response),
            ctx.commit('setCurrentAgencyId', agency_id)
          )
          .catch(error => reject(error))
      })
    },
    addAgency(ctx, params) {
      let organization_id = "d2c2d966-b1aa-4067-abf3-936afc2cc6d6"  //Currently dev workaround, real code -> ctx.organization_id;

      let body = {
        name: params.agencyName,
        email: params.email,
        phone_number: params.phone_number,
        administrator: {
            first_name: params.mainAgencyAdminFirstName,
            last_name: params.mainAgencyAdminLastName,
            email: params.mainAgencyAdminEmail
        }
      }

      if (params.branchNumber) {
        body.additional_info = [{
          name: "branch_number",
          value: params.branchNumber
        }]
      }

      return new Promise((resolve, reject) => {
        axios
          .post(`/organizations/${organization_id}/members`, body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addMemberToAgency(ctx, params) {

      const AGENCY = "agency"
      let is_admin = false;

      if (params.is_admin === 'true') {
        is_admin = true;
      }

      let body = {
        resource:{
          name: AGENCY,
          id: params.agency_id
      },
        profile: {
            first_name: params.agentFirstName,
            last_name: params.agentLastName,
            email: params.agentEmail,
        },
        is_admin
      }

      return new Promise((resolve, reject) => {
        axios
          .post(`/users`, body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    patchAgency(ctx, params) {
      let agency_id = params.id;
      let body = params.body;
    },

    //Dummy call for dev
    fetchAgenciesMock(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        resolve({
          data: agencies
        })
      })
    },
    fetchAgencyMock(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        resolve({
          results: agencies[0]
        })
      })
    },
    fetchAgencyMembers(ctx, agency_id) {
      const AGENCY = "agency"
      return new Promise((resolve, reject) => {
        axios
          .get(`/users?resource=${AGENCY}&id=${agency_id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAgencyMember(ctx, {id}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  },
}
